.root {
  margin-bottom: 1rem;
}

.title {
  color: var(--accent-base);
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 4rem;
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
}
