.tableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--ambient-basePlus75);
  align-items: center;
  font-size: 1.5rem;
}

.tableHeaderLabel {
  font-weight: bold;
  margin-right: 0.75rem;
}

.tableContentContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tableContentSplit {
  width: 49%;
  display: grid;
  grid-template-rows: 4rem repeat(16, 3rem);
}

.tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0 1rem;
}

/* For more columns, use reduced font to not wrap to next line */
.resizedFont {
  font-size: 1rem;
}

.tableHeaderCell {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  text-align: center;
}

.reportReview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  padding: 1rem 0;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-base);
  border-top: 2px solid var(--ambient-basePlus25);
  /* temporary hard code review block to be at the bottom of the page */
  /* 
    TODO: rework the ReportPageBreakControl logic to support using flexbox between
    different ReportMeasurableElement.
   */
  margin-top: 10rem;
}

.reportSignature {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.shaded {
  background-color: var(--ambient-basePlus90);
}

.certContainer {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  padding: 1rem 0;
}

.errorMessage {
  padding-top: 2rem;
  font-size: 1.5rem;
}
