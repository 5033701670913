.content {
  font-size: 1.5rem;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0.5%;
}

.divider {
  border-style: inset;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.centerAlignColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spreadRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.evenSpaceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.storageConsumptionInfoContainer {
  margin: 3rem 0;
  padding: 2rem 0;
  border-top: thin solid lightgray;
  border-bottom: thin solid lightgray;
}

.consumptionInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bigNumberContainer {
  margin-top: 2rem;
}

.centerBigNumberContainer {
  margin-top: 2rem;
  text-align: center;
}

.bigNumber {
  color: var(--ambient-base);
  font-size: 2rem;
  font-weight: bold;
}

.bigNumberDeltaPositive {
  color: var(--pink-base);
  font-size: 2rem;
  font-weight: bold;
}

.bigNumberDeltaNegative {
  color: var(--green-base);
  font-size: 2rem;
  font-weight: bold;
}

.bigNumberDeltaBlack {
  font-size: 2rem;
  font-weight: bold;
}

.deltaArrowPositive {
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-bottom: 1.5rem solid var(--pink-base);
  margin-left: 0.5rem;
}

.deltaArrowNegative {
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 1.5rem solid var(--green-base);
  margin-left: 0.5rem;
}

.blackDeltaArrowPositive {
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-bottom: 1.5rem solid black;
  margin-left: 0.5rem;
}

.blackDeltaArrowNegative {
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 1.5rem solid black;
  margin-left: 0.5rem;
}

.deltaUnitPositive {
  color: var(--pink-base);
  font-size: 1.5rem;
  margin-left: 0.3em;
}

.deltaUnitNegative {
  color: var(--green-base);
  font-size: 1.5rem;
  margin-left: 0.3em;
}

.deltaUnitBlack {
  font-size: 1.5rem;
}

.bigNumberSymbol {
  margin-right: 1em;
  color: var(--ambient-base);
  font-weight: bold;
  font-size: 1.5rem;
}

.bigNumberUnit {
  color: var(--ambient-base);
  font-weight: bold;
}

.tableCell,
.tableHeader {
  font-size: 1.25rem;
  padding: 0.5rem 0;
}

.tableCellRightAlign {
  text-align: right;
  justify-content: flex-end;
}

.monthlyConsumptionHeaderCell {
  border-top: 2px solid var(--ambient-basePlus50);
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.monthlyConsumptionContentCell {
  background-color: var(--ambient-basePlus90);
  border-top: 2px solid var(--ambient-basePlus50);
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.genericComponentContainer {
  height: 825px;
}
