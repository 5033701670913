.root {
  padding-bottom: 4rem;
}

.withSeparator {
  padding-bottom: 2.25rem;
  border-bottom: 3px solid var(--ambient-basePlus90);
}

.headerTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--ambient-basePlus90);
}

.doubleColumnEntityContent {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-template-columns: 0.6fr 1fr;
  grid-auto-rows: min-content;
}

.singleColumnEntityContent {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: 0.1fr 1fr;
  grid-auto-rows: min-content;
}

.headerTitle {
  font-size: 2.25rem;
  font-weight: bold;
  color: var(--accent-base);
}

.headerSubtitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.doubleColumnContainer {
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}

.singleColumnContainer {
  font-size: 1.5rem;
}

.fieldContainer {
  display: flex;
}

.fieldLabel {
  font-weight: bold;
}

.fieldValue {
  line-height: 1.75rem;
}
