.root {
  display: flex;
}

.applyButton {
  margin: 0.5rem 0;
  padding-left: 0rem;
  float: right;
}

.customDateOpenContainerMargin {
  margin: 0.5rem 1rem;
}

.customDateOpenContainerMargin > div {
  margin: 0.5rem 0rem;
}
