.root {
  padding: 2rem;
  margin-left: var(--application-menu-width);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: bold;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .root {
    margin-left: 0;
  }
}
