.root {
  width: 100%;
  padding: 0.75rem 3rem;
  background-color: var(--ambient-basePlus75);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
}

.label {
  font-weight: bold;
  margin-right: 0.75rem;
}

.textContainer {
  display: flex;
  align-items: center;
}
