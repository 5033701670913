.tileContainer {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.tileTitle {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.topTable {
  font-size: 1rem;
}

.topTable thead tr, .topTable thead tr th {
  border-top: none !important;
}

.tableHeader div {
  font-size: 1rem;
  font-weight: 600;
  word-wrap: break-word;
  white-space: normal;
}

.tableGroupHeader {
  font-size: 1.2rem !important;
  font-weight: 600;
}

.red {
  color: var(--notification-red);
}