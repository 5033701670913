.root {
  width: 100%;
  height: 300px;
  padding-right: 2rem;
}

.yUnitLabel {
  width: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--base50);
}