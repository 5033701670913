.nohEfficiency {
  text-align: right;
}

.legendText {
  font-size: 1.2rem;
}

.titleContainer {
  font-size: 1.5rem;
}

.nohEfficiency .title {
  font-size: 1.5rem;
  font-weight: 510;
  color: var(--medium-base);
  padding-top: 0.5rem;
}

.nohEfficiency .value {
  color: var(--accent-base);
  font-weight: 600;
  font-size: 2rem;
  float: right;
  margin-left: 0.5rem;
}

.nohContainer {
  display: grid;
  grid-template-columns: 7fr 8fr;
  height: 450px;
}

.topTable {
  font-size: 1.2rem;
}

.tableHeader div {
  font-size: 1.2rem;
  font-weight: 600;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  height: 3.4375rem;
  align-items: flex-start;
}
