.root table.table thead tr {
  font-size: 1rem;
  border-top: 3px solid var(--ambient-basePlus90);
  border-bottom: 3px solid var(--ambient-basePlus90);
}

.root table.table tbody tr:nth-child(even) {
  background-color: var(--ambient-basePlus90);
}

.table {
  font-size: 1.5rem;
}

.tableHeader {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 3rem;
}

.paddingTop {
  padding-top: 0.25rem;
}

.smallGreyText {
  font-size: 1.125rem;
  font-style: italic;
  color: var(--ambient-basePlus25);
}

.semiBold {
  font-weight: 600;
}
