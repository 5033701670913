.root {
  display: flex;
  width: 100vw;
}

.pdfRoot {
  width: 100%;
  height: 100vh;
}

.fullFrame {
  height: calc(100% - var(--top-bar-menu-height));
}

.content {
  width: 100%;
}
