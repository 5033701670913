.container {
  padding: 2rem 0 1rem 3rem;
}

.siteTitle {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.siteAddress {
  font-size: 0.875rem;
  color: #5e5e5e;
}
