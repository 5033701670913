.root {
  position: relative;
}

.capHeight {
  max-height: 100%;
}

.margin {
  padding: 2rem 3rem;
}

.fillFrame {
  height: 100%;
}

.hasFilterBar {
  padding: 2.5rem 3.5rem;
}
