.root {
  margin-left: var(--application-menu-width);
}

.tabsContainer {
  padding: 0rem 3rem;
}

@media (max-width: 768px), (max-device-width: 768px) {
  .root {
    margin-left: 0;
  }
}
