.tableContainer {
  padding: 2rem 0;
  min-height: 1700px;
}

.headerCell {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}

.cell {
  font-size: 1.25rem;
  min-height: 3rem;
}

.sensorTitleCell {
  white-space: nowrap;
  min-height: 3rem;
}

.leftPadding {
  padding-left: 0.75rem;
}

.notificationCellContent {
  font-size: 0.875rem;
  font-style: italic;
  margin-top: 0.25rem;
}
