.content {
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  margin-bottom: 2rem;
}

.cell {
  font-size: 0.875rem;
}
