.tileTitle {
  margin-bottom: 4.1rem;
  font-size: 1.5rem;
}

.sitesInTarget {
  width: 50%;
  float: left;
}

.sitesOutTarget {
  width: 50%;
  float: right;
  text-align: right;
}

.siteCount {
  font-size: 4rem;
  font-weight: 400;
}

.subText {
  font-size: 1.2rem;
  margin: 20px 0;
  width: 160px;
}

.right {
  float: right;
}

.barLabelLeft {
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  top: -15px;
}

.barLabelRight {
  font-size: 2rem;
  font-weight: 600;
  text-align: right;
  position: relative;
  top: -15px;
}

.labelContainerLeft {
  display: block;
  position: absolute;
  top: 50px;
  left: 10px;
  color: #fff;
}

.labelContainerRight {
  width: 100%;
  text-align: right;
}
