.root {
  padding: 0rem 3rem 2rem 3rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.leftHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.headerTitle {
  padding-right: 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.report {
  padding-left: 0.5rem;
}

.linkContainer {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
}
