.backButton {
  color: var(--accent-base);
  height: 100%;
  display: flex;
  align-items: center;
  background-color: black;
  width: 60px;
  justify-content: center;
  margin-left: -32px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.organizationName {
  font-size: 0.75em;
  align-items: center;
}

.backButton:hover {
  background-color: transparent;
}

.link,
.link a {
  font-size: 0.75rem;
  font-weight: normal;
}

.userMenuContainer {
  display: flex;
  align-items: center;
}

.organizationMenuContainer {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  font-size: 0.75rem;
}

.organizationMenuTitle {
  padding-left: 0.5rem;
}
