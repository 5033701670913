.content {
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  margin-bottom: 2rem;
}

.headerIcon {
  color: var(--accent-base);
  display: block;
  padding-right: 1rem;
}

.cell {
  font-size: 0.875rem;
}
