.root {
  min-height: 2.125rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
}

.empty {
  width: 15rem;
}
