.legend {
  padding-top: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.absoluteConsumptionLegendInner {
  width: 90%;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  align-items: center;
  padding: 1rem 0;
}

.absoluteConsumptionLegendInner:not(:last-of-type) {
  border-bottom: 1px solid var(--ambient-basePlus50);
}

.legendEntryInner {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 1.25rem;
}

.legendDot {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.legendText {
  font-size: 1.25rem;
  color: var(--ambient-base);
  margin-left: 0.625rem;
}
.absoluteConsumptionLegendText {
  font-size: 1.25rem;
  color: var(--ambient-base);
  margin-left: 0.5rem;
}

.absoluteConsumptionLegendTextCentered {
  font-size: 1.25rem;
  color: var(--ambient-base);
  margin-left: auto;
  margin-right: auto;
}
