.miniTile {
  box-shadow: none;
  padding: 2rem;
}

.miniTile > div {
  height: 140px;
}

.miniTile > div > div:nth-child(1) {
  font-size: 1.5rem;
}

.miniTile > div > div:nth-child(2) {
  bottom: 55px;
}

.miniTile > div > div:nth-child(2) > div {
  font-size: 2.25rem;
  font-weight: 600;
}

.miniTile > div > div:nth-child(3), .miniTile > div > div:nth-child(4) {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.miniTile:nth-child(4), .miniTile:nth-child(5), .miniTile:nth-child(6) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 2px solid var(--color-greySeparator);
  padding: 0;
}

.miniTile:nth-child(1), .miniTile:nth-child(2), .miniTile:nth-child(7), .miniTile:nth-child(8) {
  border-right: 2px solid var(--color-greySeparator);
}

.miniTile .title {
  font-weight: normal;
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 400px;
}