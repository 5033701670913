.lineIcon {
  padding-bottom: 0.4rem;
  padding-right: 0.5rem;
}

.loading {
  position: relative;
  top: 4.5rem;
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: 0;
}

.legendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.yUnitLabel {
  width: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--base50);
}

.marginRight {
  margin-right: 1rem;
}
